import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { ModalController, Platform } from "@ionic/angular";
import { Storage } from "@ionic/storage-angular";
import { AddPage } from "./pages/operations/add/add.page";
import { UserService } from "./services/user.service";
import { environment } from "../environments/environment";
import { TranslateConfigService } from "./services/translate-config.service";
import { FcmService } from "./services/fcm.service";
import { DataService } from "./services/data.service";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  selectedLanguage: string;
  appType = "operations";
  operations = false;
  production = false;
  technical = false;
  switchable = false;
  visible = false;
  user: any = {
    name: "",
    title: "",
  };
  version = environment.version;

  technical_scheme: any[] = [
    {
      name: "Spare Parts",
      icon: "cube",
      url: "/technical/parts",
    },
    {
      name: "Service Reports",
      icon: "checkmark-done",
      url: "/technical/reports",
    },
    {
      name: "Proformas",
      icon: "paper-plane",
      url: "/technical/proformas",
    },
  ];
  constructor(
    public router: Router,
    private storage: Storage,
    private userService: UserService,
    private modalCtrl: ModalController,
    public platform: Platform,
    private fcmService: FcmService,
    private translateConfigService: TranslateConfigService,
    public dataService: DataService
  ) {
    this.selectedLanguage = translateConfigService.getDefaultLanguage();
  }

  async ngOnInit() {
    await this.storage.create();
    await this.platform.ready().then(async (platform) => {
      await this.storage.get('language').then((language) => {
        if (language) {
          this.selectedLanguage = language;
        } else {
          this.selectedLanguage = this.translateConfigService.getDefaultLanguage();
        }
        this.translateConfigService.setLanguage(this.selectedLanguage);

      });
      await this.storage.get("hasLoggedIn").then((hasLoggedIn) => {
        if (!hasLoggedIn) {
          this.operations = false;
          this.production = false;
          this.technical = false;
          this.router.navigateByUrl("/login");
        } else {
          this.storage.get("user").then((user) => {
            this.user = user;
            this.userService.checkClearance();
            if (!this.user.clearance.includes("P")) this.visible = true;
          });
        }
      });
      await this.userService.getLoginEvent().then((value) => {
        value.subscribe((data) => {
          this.userService.getUser().then((user) => {
            this.user = user;
            this.storage.get("app").then((app) => {
              if (app) data = app;
              this.changeApp(data);
            });
          });
        });
      });
      if (this.platform.is("ios") || this.platform.is("android")) {
        await this.fcmService.initPush().then((token) => {
          token.subscribe((value) => {
            let data = {
              email:this.user.email,
              password:this.user.password,
              token:value
            }
            this.dataService.postData("/user/addtoken",data).subscribe((res)=>{
            })
          });
        });
      }
    });
  }
  languageChanged() {
    this.translateConfigService.setLanguage(this.selectedLanguage);
    this.storage.set("language", this.selectedLanguage);
  }

  async changeApp(app) {
    this.switchable = false;
    if (
      this.user.clearance.includes("A") ||
      this.user.clearance.includes("B")
    ) {
      this.switchable = true;
      this.appType = app;
      this.storage.set("app", app);
      this.router.navigate([this.appType], { replaceUrl: true });
    }
    switch (app) {
      case "operations":
        this.operations = true;
        this.production = false;
        this.technical = false;
        break;
      case "production":
        this.operations = false;
        this.production = true;
        this.technical = false;
        break;
      case "technical":
        this.operations = false;
        this.production = false;
        this.technical = true;
        break;
      case "login":
        this.operations = false;
        this.production = false;
        this.technical = false;

      default:
        break;
    }
  }

  async logout() {
    this.production = false;
    this.operations = false;
    this.technical = false;
    await this.storage.remove("user");
    await this.storage.remove("hasLoggedIn");
    await this.storage.remove("app");
    this.router.navigateByUrl("/login");
  }

  async newOrder() {
    const settings = {
      component: AddPage,
      componentProps: {
        user: this.user
      },
    };

    const modal = await this.modalCtrl.create(settings);
    await modal.present();
    const { data } = await modal.onDidDismiss();
  }
}
