import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class CheckLoginService implements CanLoad {

  constructor(
    public userService: UserService,
    public router: Router
  ) { }
  canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.userService.isLoggedIn().then(value => {
      if (value) {
        this.userService.checkClearance().then(route => {
          if (route == 'login') {
            return true;
          }
          this.router.navigate([route]);
        });
        return false;
      }
      return true;
    });
  }
}