import { Component, Input, OnInit } from "@angular/core";
import {
  ActionSheetController,
  AlertController,
  ModalController,
} from "@ionic/angular";
import { IonicSelectableComponent } from "ionic-selectable";
import { Order } from "src/app/interfaces/order";
import { DataService } from "src/app/services/data.service";
import { UserService } from "src/app/services/user.service";
import { format, parseISO, getDate, getMonth, getYear } from "date-fns";
import { AddProductPage } from "../add-product/add-product.page";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-add",
  templateUrl: "./add.page.html",
  styleUrls: ["./add.page.scss"],
})
export class AddPage implements OnInit {
  order = { products: [] } as Order;

  brands: Array<any>;
  countries: Array<any>;
  sellers: Array<any>;
  units: Array<any>;
  isValid = true;
  canUpdate = false;
  country;
  seller;
  brand;
  recentlyAdded = false;
  recentlyAddedProducts = [];
  @Input() ordered: Order;
  @Input() user: any;
  @Input() reserved: any;
  asStock: boolean;
  asPackaged: boolean;

  //translate
  error: string;
  success: string;
  required: string;
  add: string;
  update: string;
  delete: string;
  deleteProduct: string;
  deleteOrders: string;
  delete1: string;
  cancel: string;
  ok: string;
  serial: string;
  serialPlaceholder: string;
  addUpdate: string;

  constructor(
    public alertCtrl: AlertController,
    public dataSrvc: DataService,
    public modalCtrl: ModalController,
    public actionSheetCtrl: ActionSheetController,
    public router: Router,
    private translate: TranslateService
  ) { }

  async ngOnInit() {
    this.translateAlert();
    await this.getOperationData();
    if (this.ordered) {
      this.canUpdate = true;
      this.order = this.ordered;
    }
    if (this.reserved) {
      this.asPackaged = true;
      this.order.products = [this.reserved];
    }
  }
  translateAlert() {
    this.translate
      .get([
        "OPERATIONS.OPERATIONSPAGE.add.addDetail.error",
        "OPERATIONS.OPERATIONSPAGE.add.addDetail.success",
        "OPERATIONS.OPERATIONSPAGE.add.addDetail.required",
        "OPERATIONS.OPERATIONSPAGE.add.addDetail.add",
        "OPERATIONS.OPERATIONSPAGE.add.addDetail.update",
        "OPERATIONS.OPERATIONSPAGE.add.addDetail.delete",
        "OPERATIONS.OPERATIONSPAGE.add.addDetail.deleteProduct",
        "OPERATIONS.OPERATIONSPAGE.add.addDetail.deleteOrders",
        "OPERATIONS.OPERATIONSPAGE.add.addDetail.delete1",
        "OPERATIONS.OPERATIONSPAGE.add.addDetail.cancel",
        "OPERATIONS.OPERATIONSPAGE.add.addDetail.ok",
        "OPERATIONS.OPERATIONSPAGE.add.addDetail.serial",
        "OPERATIONS.OPERATIONSPAGE.add.addDetail.serialPlaceholder",
        "OPERATIONS.OPERATIONSPAGE.add.addDetail.addUpdate",
      ])
      .subscribe((values) => {
        this.error = values["OPERATIONS.OPERATIONSPAGE.add.addDetail.error"];
        this.success =
          values["OPERATIONS.OPERATIONSPAGE.add.addDetail.success"];
        this.required =
          values["OPERATIONS.OPERATIONSPAGE.add.addDetail.required"];
        this.add = values["OPERATIONS.OPERATIONSPAGE.add.addDetail.add"];
        this.update = values["OPERATIONS.OPERATIONSPAGE.add.addDetail.update"];
        this.delete = values["OPERATIONS.OPERATIONSPAGE.add.addDetail.delete"];
        this.deleteProduct =
          values["OPERATIONS.OPERATIONSPAGE.add.addDetail.deleteProduct"];
        this.deleteOrders =
          values["OPERATIONS.OPERATIONSPAGE.add.addDetail.deleteOrders"];
        this.delete1 =
          values["OPERATIONS.OPERATIONSPAGE.add.addDetail.delete1"];
        this.cancel = values["OPERATIONS.OPERATIONSPAGE.add.addDetail.cancel"];
        this.ok = values["OPERATIONS.OPERATIONSPAGE.add.addDetail.ok"];
        this.serial = values["OPERATIONS.OPERATIONSPAGE.add.addDetail.serial"];
        this.serialPlaceholder =
          values["OPERATIONS.OPERATIONSPAGE.add.addDetail.serialPlaceholder"];
        this.addUpdate =
          values["OPERATIONS.OPERATIONSPAGE.add.addDetail.addUpdate"];
      });
  }
  formatDate(value: string) {
    return format(parseISO(value), "dd/MM/yyyy");
  }

  getGroupText(port, portIndex: number, ports) {
    if (port.id == 0) {
      return "Favorites";
    }
    if (port.id == 17) {
      return "Others";
    }
  }

  searchCountry(event: { component: IonicSelectableComponent; text: string }) {
    const text = event.text.trim().toLowerCase();
    event.component.startSearch();
    if (!text) {
      event.component.items = this.countries;
      event.component.endSearch();
      return;
    }
    event.component.items = this.countries.filter((item) => {
      return (
        item.name.toLowerCase().indexOf(text) !== -1 ||
        item.alpha_2.toLowerCase().indexOf(text) !== -1 ||
        item.alpha_3.toLowerCase().indexOf(text) !== -1
      );
    });
    event.component.endSearch();
  }

  async getOperationData() {
    this.dataSrvc.getData("/operations/data", this.user).subscribe(async (response) => {
      if (response.status == "ok") {
        if (response.data.brands.status == "ok") {
          this.brands = response.data.brands.data;
          if (this.ordered) {
            this.brand = this.brands.find(
              (brand) => brand.name == this.order.brand
            );
          }
        }
        if (response.data.countries.status == "ok") {
          this.countries = response.data.countries.data.sort((a, b) => {
            return a.id - b.id;
          });
          if (this.ordered) {
            this.country = this.countries.find(
              (country) => country.name == this.order.country
            );
          }
        }
        if (response.data.sellers.status == "ok") {
          this.sellers = response.data.sellers.data;
          if (this.ordered) {
            this.seller = this.sellers.find(
              (seller) => seller.name == this.order.seller
            );
          }
        }
        if (response.data.units.status == "ok")
          this.units = response.data.units.data;
      } else {
        console.log("error");
      }
    });
  }

  async addOrder() {
    var data = {
      ...this.order,
    };
    console.log(data);
    if (
      !data.number ||
      !data.brand ||
      !data.seller ||
      !data.company ||
      !data.country ||
      !data.address ||
      data.products.length == 0
    ) {
      this.isValid = false;
      this.presentAlert(this.error, "", this.required);
      return;
    }
    data["email"] = this.user.email;
    data["password"] = this.user.password;
    data.editor = this.user.name;
    data.products.forEach((product) => {
      product.order_number = data.number;
    });
    data.products = JSON.stringify(data.products);
    console.log(data);

    this.dataSrvc.postData("/operations/add", data).subscribe((response) => {
      if (response.status == "ok") {
        this.presentAlert(this.success, "", this.add).then(() => {
          this.modalCtrl.dismiss();
        });
      } else {
        this.presentAlert(this.error, "", response);
      }
    });
  }

  async presentAlert(header, subHeader, message) {
    const alert = await this.alertCtrl.create({
      header: header,
      subHeader: subHeader,
      message: message,
      buttons: [this.ok],
    });

    await alert.present();
    const result = await alert.onDidDismiss();
    return result;
  }

  async presentModal() {
    const settings = {
      component: AddProductPage,
      componentProps: {
        units: this.units,
      },
    };

    const modal = await this.modalCtrl.create(settings);
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data) {
      if (this.canUpdate) {
        this.recentlyAdded = true;
        this.recentlyAddedProducts.push(data.product);
      } else {
        this.order.products.push(data.product);
      }
    }
  }

  async removeProduct(i) {
    const actionSheet = await this.actionSheetCtrl.create({
      header: this.deleteProduct,
      buttons: [
        {
          text: this.delete1,
          role: "destructive",
        },
        {
          text: this.cancel,
          role: "cancel",
        },
      ],
    });

    await actionSheet.present();

    const { role } = await actionSheet.onDidDismiss();

    if (role === "destructive") {
      if (this.canUpdate) {
        this.recentlyAddedProducts.splice(i, 1);
        if (this.recentlyAddedProducts.length == 0) this.recentlyAdded = false;
      } else {
        this.order.products.splice(i, 1);
      }
    }
  }

  async deleteOrder(number) {
    var data = {
      number: number,
    };
    const alert = await this.alertCtrl.create({
      header: this.deleteOrders,
      buttons: [
        {
          text: this.cancel,
          role: "cancel",
        },
        {
          text: this.delete1,
          handler: () => {
            data["email"] = this.user.email;
            data["password"] = this.user.password;
            this.dataSrvc.postData("/operations/delete", data).subscribe((response) => {
              if (response.status == "ok") {
                this.presentAlert(this.success, "", this.delete);
                this.modalCtrl.dismiss({
                  dismissed: true,
                });
              } else {
                this.presentAlert(this.error, "", response.message);
              }
            });
          },
        },
      ],
    });

    await alert.present();
  }

  //For updating order

  async presentAlertPrompt(product) {
    const alert = await this.alertCtrl.create({
      header: this.addUpdate,
      inputs: [
        {
          name: "serial",
          type: "text",
          value: product.serial,
          placeholder: this.serialPlaceholder,
        },
      ],
      buttons: [
        {
          text: this.cancel,
          role: "cancel",
          cssClass: "secondary",
          handler: () => {
            console.log("Confirm Cancel");
          },
        },
        {
          text: "Ok",
          handler: (data) => {
            console.log("Confirm Ok");
            if (data.serial) {
              product.serial = data.serial;
            } else {
              product.serial = "";
            }
          },
        },
      ],
    });

    await alert.present();
  }

  async presentModalUpdate(product, index) {
    const settings = {
      component: AddProductPage,
      componentProps: {
        units: this.units,
        orderedProduct: product,
      },
    };

    const modal = await this.modalCtrl.create(settings);
    await modal.present();
    modal.onDidDismiss().then(async (res) => {
      if (res.data) {
        if (res.data.product) {
          this.order.products[index] = res.data.product;
        }
        if (res.data.deleted) {
          this.order.products.splice(index, 1);
        }
      }
    });
  }

  canDismiss() {
    this.modalCtrl.dismiss();
  }

  randomCodeGenerator(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  toStock() {
    this.asStock = true;
    this.order.number = "ST" + this.randomCodeGenerator(100000, 999999);
    this.order.company =
      "KRİSTAL ENDÜSTRİYEL MUTFAK ÇAMAŞIRHANE MAKİNE PAZARLAMA SAN. VE TİC. A.Ş.";
    this.order.brand = "Crystal";
    this.order.country = "Turkey";
    this.order.seller = "Crystal";
    this.order.address = "Organize Sanayi Bölgesi 1. Kısım 2. Cadde 07190";
  }

  async updateOrder() {
    var data = {
      ...this.order,
    };
    if (
      !data.number ||
      !data.brand ||
      !data.seller ||
      !data.company ||
      !data.country ||
      data.products.length == 0
    ) {
      this.isValid = false;
      this.presentAlert(this.error, "", this.required);
      return;
    }
    data["email"] = this.user.email;
    data["password"] = this.user.password;
    data.editor = this.user.name;
    data.products.forEach((product) => {
      product.order_number = data.number;
    });
    if (this.recentlyAdded) {
      this.recentlyAddedProducts.forEach((product) => {
        product.order_number = data.number;
      });
      data["recentlyAddedProducts"] = JSON.stringify(
        this.recentlyAddedProducts
      );
    }
    data.products = JSON.stringify(data.products);
    this.dataSrvc.postData("/operations/update", data).subscribe((response) => {
      if (response.status == "ok") {
        this.presentAlert(this.success, "", this.update).then(() => {
          this.modalCtrl.dismiss({
            dismissed: true,
          });
        });
      } else {
        this.presentAlert(this.error, "", response.message);
      }
    });
  }

  reservedToPackaged() {
    var data = {
      ...this.order,
    };
    if (
      !data.number ||
      !data.brand ||
      !data.seller ||
      !data.company ||
      !data.country ||
      data.products.length == 0
    ) {
      this.isValid = false;
      this.presentAlert(this.error, "", this.required);
      return;
    }
    data["email"] = this.user.email;
    data["password"] = this.user.password;
    data.editor = this.user.name;
    data.products.forEach((product) => {
      product.order_number = data.number;
      product.status = "Packaged";
      product.reservedBy = "";
    });
    data.products = JSON.stringify(data.products);
    this.dataSrvc.postData("/operations/topackaged", data).subscribe((response) => {
      if (response.status == "ok") {
        this.presentAlert(this.success, "", this.add).then(() => {
          this.modalCtrl.dismiss({
            data: "dismissed",
          });
        });
      } else {
        this.presentAlert(this.error, "", response.message);
      }
    });
  }
}
