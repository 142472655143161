import { Injectable } from '@angular/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class FcmService {

  constructor(
    private router: Router,
    private userService: UserService
  ) { }

  async initPush() {
    let subject = new Subject<any>();
    let permStatus = await PushNotifications.checkPermissions();


    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
      throw new Error('User denied permissions!');
    }

    await PushNotifications.register();

    PushNotifications.addListener(
      'registration',
      (token) => {
        subject.next(token.value);
        PushNotifications.removeAllDeliveredNotifications();
      }
    );

    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Error: ' + JSON.stringify(error));
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      async (notification) => {
        console.log('Push received: ' + JSON.stringify(notification));
      }
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      async (notification) => {
        const data = notification.notification.data;
        console.log('Action performed: ' + JSON.stringify(data));
        this.userService.checkClearance().then(route => {
          this.router.navigate([route]);
          PushNotifications.removeAllDeliveredNotifications();
        });
      }
    );
    return subject.asObservable();
  }
}