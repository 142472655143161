import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CheckLoginService } from './services/check-login.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    canLoad: [CheckLoginService],
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'operations',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/operations/operations.module').then(m => m.OperationsPageModule)

      },
      {
        path: 'stock',
        loadChildren: () => import('./pages/operations/list/list.module').then(m => m.ListPageModule)
      }
    ],
  },
  {
    path: 'production',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/production/production.module').then(m => m.ProductionPageModule)

      },
      {
        path: 'stock',
        loadChildren: () => import('./pages/production/list/list.module').then(m => m.ListPageModule)
      }
    ],
  },  
  {
    path: 'technical',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/technical/technical.module').then(m => m.TechnicalPageModule)

      }
    ],
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: '**',
    redirectTo: 'login'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
