import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  baseURL = 'https://wsapi.crystal.com.tr/';
  hasLoggedIn = 'hasLoggedIn';
  private onLogin = new Subject<any>();

  constructor(
    private storage: Storage
  ) { }

  async login(username: string, password: string) {
    let body = {
      "email": username,
      "password": password
    }
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    let url = this.baseURL + 'user/login';
    return await fetch(url, {
      method: 'POST',
      body: new URLSearchParams(body),
      headers: myHeaders,
      redirect: 'follow'
    }).then(response => response.json());
  }

  async isLoggedIn() {
    return await this.storage.get(this.hasLoggedIn);
  }

  async setUser(user) {
    await this.storage.set(this.hasLoggedIn, true);
    await this.storage.set('user', user);
    return await this.checkClearance();
  }

  async getUser() {
    return await this.storage.get('user');
  }

  async checkClearance() {
    return await this.getUser().then(user => {
      if (user) {
        switch (true) {
          case user.clearance.includes('A'):
            this.storage.get('app').then(app => {
              if (app) return app;
              return 'operations';
            });
          case user.clearance.includes('O'):
            console.log(user.title, 'logged in');
            this.loginEvent('operations');
            return 'operations';
          case user.clearance.includes('P') || user.clearance.includes('S') || user.clearance.includes('B') || user.clearance.includes('L'):
            console.log(user.title, 'logged in');
            this.loginEvent('production');
            return 'production';
          case user.clearance.includes('Q'):
            console.log(user.title, 'logged in');
            this.loginEvent('production');
            return 'production';
          case user.clearance.includes('T'):
            console.log(user.title, 'logged in');
            this.loginEvent('technical');
            return 'technical';
          default:
            this.storage.remove('hasLoggedIn');
            console.log('This user does not have clearance');
            this.loginEvent('login');
            return 'login';
        }
      } else {
        this.storage.remove('hasLoggedIn');
        console.log('This user does not have clearance');
        this.loginEvent('login');
        return 'login';
      }
    });
  }

  async loginEvent(data: any) {
    this.onLogin.next(data);
  }

  async getLoginEvent(): Promise<Subject<any>> {
    return this.onLogin;
  }

}
