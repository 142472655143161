import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  baseURL = 'https://wsapi.crystal.com.tr';
  user: any[] = [];

  constructor(
    private userService: UserService
  ) { }

  fetchRequest(url: string, data: any, method: string,): Observable<any> {
    const response = new Subject<any>();
    const headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    const request = new Request(url, {
      method: method,
      body: new URLSearchParams(data),
      headers: headers
    });
    fetch(request).then(data => {
      data.json().then(res => {
        response.next(res);
      });
    });
    return response.asObservable();
  }

  getData(section: string, data: any): Observable<any> {
    const subject = new Subject<any>();
    this.fetchRequest(this.baseURL + section, data, 'POST').subscribe(response => {
      if (response.status === 'ok') {
        subject.next(response);
      } else {
        subject.error(response);
      }
    });
    return subject.asObservable();
  }

  postData(section: string, data: any): Observable<any> {
    const subject = new Subject<any>();
    this.fetchRequest(this.baseURL + section, data, 'POST').subscribe(response => {
      subject.next(response);
    });
    return subject.asObservable();
  }
}
