import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  ActionSheetController,
  AlertController,
  ModalController,
} from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { format, parseISO } from "date-fns";
import { Product } from "src/app/interfaces/order";
import { DataService } from "src/app/services/data.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-add-product",
  templateUrl: "./add-product.page.html",
  styleUrls: ["./add-product.page.scss"],
})
export class AddProductPage implements OnInit {
  product = {} as Product;
  stats = [
    {
      id: 1,
      name: "Production",
    },
    {
      id: 2,
      name: "Packaged",
    },
    {
      id: 3,
      name: "Ready to Ship",
    },
    {
      id: 4,
      name: "Shipped",
    },
    {
      id: 5,
      name: "Stock",
    },
    {
      id: 6,
      name: "Reserved",
    },
  ];
  translatedStats = [];
  canUpdate = false;
  @Input() units;
  translatedUnits = [];
  @Input() orderedProduct;
  status;
  unit;
  isValid: boolean = true;

  //translate dissmiss
  header: string = "";
  discardChanges: string = "";
  keepEditing: string = "";

  //translate delete product
  productHeader: string = "";
  cancelText: string = "";
  deleteText: string = "";

  btn: string = "";
  error: string = "";
  success: string = "";
  required: string = "";
  delete: string = "";

  constructor(
    public modalController: ModalController,
    public alertCtrl: AlertController,
    public actionSheetCtrl: ActionSheetController,
    public router: Router,
    public dataSrvc: DataService,
    public userSrvc: UserService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.units.forEach((unit) => {
      this.translatedUnits.push({
        id: unit.id,
        name: this.translate.instant("OPERATIONS.OPERATIONSPAGE.detail."+unit.name),
      });
    });

    this.stats.forEach((stat) => {
      this.translatedStats.push({
        id: stat.id,
        name: this.translate.instant("OPERATIONS.OPERATIONSPAGE.add.addproduct."+stat.name),
      });
    });
    this.translateAlert();
    if (this.orderedProduct) {
      this.canUpdate = true;
      this.product = this.orderedProduct;
      this.status = this.translatedStats.find(
        (country) => country.name == this.translate.instant("OPERATIONS.OPERATIONSPAGE.add.addproduct."+this.product.status)
      );
      this.unit = this.translatedUnits.find(
        (country) => country.name == this.translate.instant("OPERATIONS.OPERATIONSPAGE.detail."+this.product.unit)
      );
      this.product.quantity = 1;
    }
  }

  translateUnit(unit){
    this.product.unit = this.units.find((u) => u.id == unit.id).name;
  }

  translateStatus(status) {
    this.product.status = this.stats.find((s) => s.id == status.id).name;
  }

  translateAlert() {
    this.translate
      .get([
        "OPERATIONS.OPERATIONSPAGE.add.addproduct.btn",
        "OPERATIONS.OPERATIONSPAGE.add.addproduct.error",
        "OPERATIONS.OPERATIONSPAGE.add.addproduct.success",
        "OPERATIONS.OPERATIONSPAGE.add.addproduct.required",
        "OPERATIONS.OPERATIONSPAGE.add.addproduct.delete",
      ])
      .subscribe((text) => {
        this.btn = text["OPERATIONS.OPERATIONSPAGE.add.addproduct.btn"];
        this.error = text["OPERATIONS.OPERATIONSPAGE.add.addproduct.error"];
        this.success = text["OPERATIONS.OPERATIONSPAGE.add.addproduct.success"];
        this.required =
          text["OPERATIONS.OPERATIONSPAGE.add.addproduct.required"];
        this.delete = text["OPERATIONS.OPERATIONSPAGE.add.addproduct.delete"];
      });
  }

  formatDate(value: string) {
    return format(parseISO(value), "dd/MM/yyyy");
  }

  async presentAlert(header, subHeader, message) {
    const alert = await this.alertCtrl.create({
      header: header,
      subHeader: subHeader,
      message: message,
      buttons: [this.btn],
    });
    await alert.present();
  }

  async canDismiss() {
    this.translate
      .get("OPERATIONS.OPERATIONSPAGE.add.addproduct.dissmiss.header")
      .subscribe((text) => {
        this.header = text;
      });
    this.translate
      .get("OPERATIONS.OPERATIONSPAGE.add.addproduct.dissmiss.discardChanges")
      .subscribe((text) => {
        this.discardChanges = text;
      });
    this.translate
      .get("OPERATIONS.OPERATIONSPAGE.add.addproduct.dissmiss.keepEditing")
      .subscribe((text) => {
        this.keepEditing = text;
      });
    const actionSheet = await this.actionSheetCtrl.create({
      header: this.header,
      buttons: [
        {
          text: this.discardChanges,
          role: "destructive",
        },
        {
          text: this.keepEditing,
          role: "cancel",
        },
      ],
    });

    await actionSheet.present();

    const { role } = await actionSheet.onDidDismiss();

    if (role === "destructive") {
      this.modalController.dismiss();
    }

    return false;
  }

  prepareOrder() {
    
    if (
      !this.product.name ||
      !this.product.quantity ||
      !this.product.unit ||
      !this.product.properties
    ) {
      this.isValid = false;
      this.presentAlert(this.error, "", this.required);
      return;
    }

    this.canUpdate = false;
    this.modalController.dismiss({
      product: this.product,
    });
  }

  async deleteProduct(id) {
    var data = {
      product_id: id,
    };

    this.translate
      .get("OPERATIONS.OPERATIONSPAGE.add.addproduct.product.header")
      .subscribe((text) => {
        this.productHeader = text;
      });

    this.translate
      .get("OPERATIONS.OPERATIONSPAGE.add.addproduct.product.delete")
      .subscribe((text) => {
        this.deleteText = text;
      });

    this.translate
      .get("OPERATIONS.OPERATIONSPAGE.add.addproduct.product.cancel")
      .subscribe((text) => {
        this.cancelText = text;
      });

    const alert = await this.alertCtrl.create({
      header: this.productHeader,
      buttons: [
        {
          text: this.cancelText,
          role: "cancel",
        },
        {
          text: this.deleteText,
          handler: () => {
            this.userSrvc.getUser().then((user) => {
              data["email"] = user.email;
              data["password"] = user.password;
              this.dataSrvc
                .postData("/operations/delete", data)
                .subscribe((response) => {
                  if (response.status == "ok") {
                    this.presentAlert(this.success, "", this.delete);
                    this.modalController.dismiss({
                      deleted: true,
                    });
                  } else {
                    this.presentAlert(this.error, "", response.message);
                  }
                });
            });
          },
        },
      ],
    });

    await alert.present();
  }
}
